<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Items" subtitle="Manage the badges, merchandise, and any other purchasable items." :readonly="$authorised('con/registration/access', 'read')">

		<app-content-head-actions-item text="Create" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-checklist v-model="filter.status" text="Status" tooltip="Select status" :options="$util.options($constants.registration.item.statusName)" />
		<app-content-filters-checklist v-model="filter.type" text="Type" tooltip="Select types" :options="$util.options($constants.registration.item.typeName)" />
		<app-content-filters-checklist v-model="filter.badge" valueKey="id" text="Badge" tooltip="Select badges" :options="references.badges" />
		<app-content-filters-checklist v-model="filter.ticket" valueKey="id" text="Ticket" tooltip="Select tickets" :options="references.tickets" />
		<app-content-filters-select v-model="filter.sort" :alwaysLabel="true" :alwaysValue="true" text="Sort by" tooltip="Change sorting" :options="$util.options(sort)" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no items found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column />
				<app-table-column text="Name" />
				<app-table-column text="Type" />
				<app-table-column text="Price" />
				<app-table-column align="center" text="Sold" />
				<app-table-column />

			</app-table-head>

			<app-table-body :sortable="sortedIndex" v-on:sorted="onSorted">

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-label text="Online" type="success" v-if="item.status === 1" />
					<app-table-cell-label text="Offline" type="danger" v-if="item.status === 0" />
					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :text="$constants.registration.item.typeName[item.type]" />
					<app-table-cell-text v-if="!item.priceRange" :text="item.price | formatMoney" />
					<app-table-cell-text v-if="item.priceRange" :text="priceRange(item)" />
					<app-table-cell-text align="center" :text="item.count.sold" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			pagination: {
				perpage: 100000
			},
			sort: {
				name: 'Name',
				price: 'Price',
				sold: 'Sold',
				type: 'Type'
			},
			references: {
				badges: [],
				tickets: []
			},
			filter: {
				badge: [],
				ticket: [],
				status: [],
				type: [],
				sort: 'name'
			},
			layout: '64px auto 200px 200px 80px 24px',
			endpoint: 'convention/registration/items',
			live: 'convention/registration/items'
		}

	},

	methods: {

		priceRange: function(item) {

			if (item.priceRange.from === item.priceRange.to) return this.$options.filters.formatMoney(item.priceRange.from)

			return [
				this.$options.filters.formatMoney(item.priceRange.from),
				'-',
				this.$options.filters.formatMoney(item.priceRange.to)
			].join(' ')

		}

	}

}

</script>

<style scoped>

</style>