import { render, staticRenderFns } from "./ConventionRegistrationItems.vue?vue&type=template&id=2ea0508e&scoped=true&"
import script from "./ConventionRegistrationItems.vue?vue&type=script&lang=js&"
export * from "./ConventionRegistrationItems.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ea0508e",
  null
  
)

export default component.exports